import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Box, Container, Fab, Tooltip, Typography, IconButton, Snackbar } from '@mui/material';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import useMediaQuery from '@mui/material/useMediaQuery';  // Import useMediaQuery from @mui/material
import HomeIcon from '@mui/icons-material/Home';
import PublicShare from '../../utils/PublicShare';
import LogoutIcon from '@mui/icons-material/Logout';
import PublicIcon from '@mui/icons-material/Public';
import UserDetails from './sections/UserDetails';
import Favourites from './sections/Favourites';
import Qualifications from './sections/Qualifications';
import MyStories from './sections/MyStories';
import Experience from './sections/Experience';
import StoryOfMyLife from "./sections/StoryOfMyLife.js";
import config from './config.json';
import fetchProfileHelper from '../../helperFunctions/fetchProfileHelper';
import { useLogin } from '../../contexts/LoginContext';
import { useAxios } from '../../contexts/AxiosContext';
import logger from "../../logger.js";
import { useNavigate, useLocation } from 'react-router-dom';
import combinedSchema from './schemas/combinedSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTheme } from '../../contexts/ThemeContext';
import PublicOrderPreference from './sections/publicOrderPrefernce.js';
import SortIcon from '@mui/icons-material/Sort';
import CoWriterManagementDialog from './FormAddons/CoWriterManagementDialog.js'; // Assuming the dialog component will be in a separate file
import { getValue } from 'tsparticles-engine';

const sections = {
    userDetails: UserDetails,
    favourites: Favourites,
    qualifications: Qualifications,
    myStories: MyStories,
    experience: Experience,
    storyofmylife: StoryOfMyLife,
};

const sectionMap = {
    addStory: { index: 1, step: 0 },
    addBio: { index: 0, step: 0 },
    addLanguage: { index: 0, step: 1 },
    addSocials: { index: 0, step: 2 },
    addQualification: { index: 0, step: 3 },
    addExperience: { index: 2, step: 0 },
    addMovie: { index: 3, step: 'movie' },
    addSeries: { index: 3, step: 'series' },
    addDocumentary: { index: 3, step: 'documentary' },
    addGame: { index: 3, step: 'game' },
    addBook: { index: 3, step: 'book' },
    addStoryOfMyLife: { index: 4, step: 0 }
};

const setInterestsFavourites = (category, profileData, setValue) => {
    setValue(`interests.favourites.${category}.value`,
        profileData.interests?.favourites?.[category]?.value !== undefined ?
            profileData.interests?.favourites?.[category].value : []
    );
    setValue(`interests.favourites.${category}.privacy`,
        profileData.interests?.favourites?.[category]?.privacy !== undefined ?
            profileData.interests?.favourites?.[category].privacy : false
    );
};

const ProfileFormComponent = () => {
    const location = useLocation();
    const [triggerAdd, setTriggerAdd] = useState(false);
    const methods = useForm({
        resolver: zodResolver(combinedSchema),
        defaultValues: {
            userDetails: {
                fullName: { value: '', privacy: false },
                profile_path: { value: '', privacy: false },
                email: { value: '', privacy: false },
                phone: { value: '', privacy: false },
                location: { value: '', privacy: false },
                linkedin: { value: '', privacy: false },
                imdbProfileLink: { value: '', privacy: false },
                website: { value: '', privacy: false },
                languages: [{ value: '', privacy: false, lsrw: { l: false, s: 0, r: 0, w: 0 } }],
                DOB: { value: '', privacy: true },
                jobTitles: []
            },
            qualifications: {
                awards: [{ name: '', year: '', awardedBy: '', privacy: false }],
                education: [{ institution: '', degree: '', privacy: false, fromDate: '', toDate: '' }],
                workExperience: [{ company: '', position: '', privacy: false, fromDate: '', toDate: '' }],
                skills: { value: [] }
            },
            interests: {
                favourites: {
                    series: { value: [], privacy: false },
                    movie: { value: [], privacy: false },
                    genre: { value: [], privacy: false },
                    documentary: { value: [], privacy: false },
                    writer: { value: [], privacy: false },
                    director: { value: [], privacy: false },
                    book: { value: [], privacy: false },
                    game: { value: [], privacy: false },
                }
            },
            ownWorks: {
                portfolio: [{
                    poster: '', name: '', logLine: '', genre_v2: '', format: '', privacy: false, status: null, credit: [], proof_link: '', language: '',
                    assetsAvailable: [], // Initialize with an empty array
                    formatDetails: { runtime: null, seasons: null, episodes: null, episodeRuntime: null } // Add this line

                }]
            },
            experience: [],
            storyOfmyLife: { value: '', privacy: false },
            userPreference: {
                publicOrder: ['ownWorks', 'experience', 'favourites']
            },
        }
    });

    const { setValue, trigger, getValues, formState: { errors } } = methods;
    const [media, setMedia] = useState({ movie: [], series: [], documentary: [], director: [], game: [], book: [], writer: [] });
    const [selectedTab, setSelectedTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const { logout, userId } = useLogin();
    const axios = useAxios();
    const navigate = useNavigate();
    const { theme, toggleTheme } = useTheme();
    const bgColor = theme.palette.mode === "dark" ? "black" : "white";
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const textColor = theme.palette.text.primary;
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [saving, setSaving] = useState(false);
    const [checklistOpen, setChecklistOpen] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: '', severity: '' });
    const handleCloseAlert = () => {
        setAlert({ open: false, message: '', severity: '' });
    };

    const showAlert = (message, severity) => {
        setAlert({ open: true, message, severity });
    };

    const [isCoWriterDialogOpen, setIsCoWriterDialogOpen] = useState(false);

    const handleCoWriterDialogOpen = () => {
        setIsCoWriterDialogOpen(true);
    };

    const handleCoWriterDialogClose = () => {
        setIsCoWriterDialogOpen(false);
    };

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };


    const [pubProfileLink, setPubProfileLink] = useState(() => {
        const userDataJson = localStorage.getItem('userData');
        return userDataJson ? JSON.parse(userDataJson).pubProfileLink : '';
    });

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const profileData = await fetchProfileHelper(axios, userId);
                // console.log("profileData: ", profileData);
                if (profileData) {
                    Object.keys(profileData.userDetails).forEach(key => {
                        setValue(`userDetails.${key}`, profileData.userDetails[key]);
                    });
                    setValue('qualifications.awards', Array.isArray(profileData.qualifications.awards) ? profileData.qualifications.awards : []);
                    setValue('qualifications.education', Array.isArray(profileData.qualifications.education) ? profileData.qualifications.education : []);
                    setValue('qualifications.workExperience', Array.isArray(profileData.qualifications.workExperience) ? profileData.qualifications.workExperience : []);
                    setValue('ownWorks.portfolio', Array.isArray(profileData.originalIps) ? profileData.originalIps : []);
                    setValue('experience', Array.isArray(profileData.experience) ? profileData.experience : []);
                    setValue('ownWorks.portfolio', Array.isArray(profileData.originalIps) ? profileData.originalIps.map(work => ({
                        ...work,
                        formatDetails: work.formatDetails || { runtime: null, seasons: null, episodes: null, episodeRuntime: null },
                        assetsAvailable: work.assetsAvailable || [], // Ensure assetsAvailable is initialized,
                        coWriters: work.coWriters,
                        _id: work._id || '',
                    })) : []);
                    const categories = ['series', 'movie', 'genre', 'documentary', 'writer', 'director', 'game', 'book'];
                    categories.forEach(category => {
                        setInterestsFavourites(category, profileData, setValue);
                    });
                    setValue('storyOfmyLife.value', profileData.storyOfmyLife?.value || '');
                    setValue('userPreference.publicOrder', profileData.userPreference?.publicOrder || ['ownWorks', 'experience', 'favourites']);
                    // console.log("Get Values: ", getValues());
                }
            } catch (error) {
                console.error('Error fetching profile:', error);
                logger(`Error fetching profile: ${error.message}`, 'error', ['env:production', 'issue:profile-fetch'], 'soozh-beta-frontend');
                showAlert('Error fetching profile data. Please try again later.', 'error');
                navigate(`/`);
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
        const fetchMedia = async () => {
            try {
                const response = await axios.get(`/mediaquery/${userId}/media_v2`);
                if (response.data) {
                    setMedia(response.data || {});
                }
                // console.log("Media: ", response.data);
            } catch (err) {
                console.log(err.message);
            }
        };

        fetchMedia();

    }, [userId, setValue, axios, navigate]);

    const watchedUserDetails = useWatch({ name: 'userDetails', control: methods.control });
    const watchedOwnWorks = useWatch({ name: 'ownWorks.portfolio', control: methods.control });
    const watchedQualifications = useWatch({ name: 'qualifications.workExperience', control: methods.control });
    const watchedInterests = useWatch({ name: 'interests.favourites', control: methods.control });
    const watchedStoryOfMyLife = useWatch({ name: 'storyOfmyLife.value', control: methods.control });

    useEffect(() => {
        const redirectSection = location.state?.redirectSection;
        if (redirectSection && sectionMap[redirectSection] !== undefined) {
            setSelectedTab(sectionMap[redirectSection].index);
        }
    }, [location.state]);

    const checkPersonalDetails = () => {
        const { fullName, email, phone, location, profile_path } = watchedUserDetails;
        return fullName.value && email.value && phone.value && location.value && profile_path.value;
    };

    const checkStories = () => {
        return watchedOwnWorks && watchedOwnWorks.length > 0;
    };

    const checkExperiences = () => {
        return watchedQualifications && watchedQualifications.length > 0;
    };

    const checkFavourites = () => {
        const categories = ['series', 'movie', 'genre', 'documentary', 'writer', 'director', 'game', 'book'];
        return categories.some(category => {
            const favourites = watchedInterests?.[category]?.value;
            return favourites && favourites.length > 0;
        });
    };

    const checkStoryOfMyLife = () => {
        return watchedStoryOfMyLife && watchedStoryOfMyLife.length >= 1000;
    };
    const toggleChecklist = () => {
        setChecklistOpen(prevState => !prevState);
    };
    useEffect(() => {
        if (checkPersonalDetails() && checkStories() && checkExperiences() && checkFavourites() && checkStoryOfMyLife()) {
            setChecklistOpen(false);
        }
    }, [watchedUserDetails, watchedOwnWorks, watchedQualifications, watchedInterests, watchedStoryOfMyLife]);


    const handleNextSection = () => {
        setSelectedTab((prevTab) => (prevTab + 1) % config.sections.length);
    };

    const handleChange = async (event, newValue) => {
        // Validate the current section first
        const currentSection = config.sections[selectedTab].path;
        const currentResult = await trigger(currentSection);
        // console.log("Current selection: ", currentSection, currentResult); // Log the current section and result
        // console.log(getValues())

        if (!currentResult) {
            // Focus on the first error field in the current section
            const firstError = Object.keys(errors).find(error => error.startsWith(currentSection));
            if (firstError) {
                const element = document.querySelector(`[name="${firstError}"]`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    element.focus();
                }
            }
            return; // Prevent changing the tab
        }

        // If the current section is valid, change the tab
        setSelectedTab(newValue);
    };

    const onSubmitProfile = useCallback(async (data) => {
        setSaving(true);

        // Ensure fields are consistent with status
        data.ownWorks.portfolio.forEach(work => {
            if (work.status !== "Acquired") {
                work.credit = [];
            }
            if (work.status !== "Available") {
                work.assetsAvailable = [];
            }
        });

        data.ownWorks.portfolio.forEach(work => {
            if (work.formatDetails) {
                work.formatDetails.runtime = work.formatDetails.runtime !== null ? Number(work.formatDetails.runtime) : null;
                work.formatDetails.seasons = work.formatDetails.seasons !== null ? Number(work.formatDetails.seasons) : null;
                work.formatDetails.episodes = work.formatDetails.episodes !== null ? Number(work.formatDetails.episodes) : null;
                work.formatDetails.episodeRuntime = work.formatDetails.episodeRuntime !== null ? Number(work.formatDetails.episodeRuntime) : null;
            }
        });
        try {
            const profileData = {
                userDetails: {
                    fullName: data.userDetails.fullName || { value: '', privacy: false },
                    profile_path: data.userDetails.profile_path || { value: '', privacy: false },
                    email: data.userDetails.email || { value: '', privacy: true },
                    phone: data.userDetails.phone || { value: '', privacy: true },
                    location: data.userDetails.location || { value: '', privacy: false },
                    linkedin: data.userDetails.linkedin || { value: '', privacy: false },
                    imdbProfileLink: data.userDetails.imdbProfileLink || { value: '', privacy: false },
                    website: data.userDetails.website || { value: '', privacy: false },
                    languages_v2: data.userDetails.languages_v2 || [{ value: '', privacy: false, lsrw: { l: 1, s: 1, r: 1, w: 1 } }],
                    creativeTraits: data.userDetails.creativeTraits || { value: [], privacy: false },
                    DOB: data.userDetails.DOB || { value: [], privacy: true },
                    jobTitles: data.userDetails.jobTitles || [],
                },
                interests: {
                    favourites: {
                        series: data.interests?.favourites?.series || { value: [], privacy: false },
                        movie: data.interests?.favourites?.movie || { value: [], privacy: false },
                        genre: data.interests?.favourites?.genre || { value: [], privacy: false },
                        documentary: data.interests?.favourites?.documentary || { value: [], privacy: false },
                        writer: data.interests?.favourites?.writer || { value: [], privacy: false },
                        director: data.interests?.favourites?.director || { value: [], privacy: false },
                        book: data.interests?.favourites?.book || { value: [], privacy: false },
                        game: data.interests?.favourites?.game || { value: [], privacy: false }
                    }
                },
                qualifications: {
                    awards: Array.isArray(data.qualifications.awards) ? data.qualifications.awards.map(award => ({
                        name: award.name,
                        year: award.year,
                        awardedBy: award.awardedBy,
                        privacy: award.privacy || false
                    })) : [],
                    education: Array.isArray(data.qualifications.education) ? data.qualifications.education.map(edu => ({
                        institution: edu.institution,
                        degree: edu.degree,
                        privacy: edu.privacy || false,
                        fromDate: edu.fromDate || '',
                        toDate: edu.toDate || ''
                    })) : [],
                    workExperience: Array.isArray(data.qualifications.workExperience) ? data.qualifications.workExperience.map(work => ({
                        company: work.company,
                        position: work.position,
                        privacy: work.privacy || false,
                        fromDate: work.fromDate || '',
                        toDate: work.toDate || ''
                    })) : [],
                },
                ownWorks: {
                    portfolio: Array.isArray(data.ownWorks.portfolio) ? data.ownWorks.portfolio.map(work => ({
                        poster: work.poster,
                        name: work.name,
                        logLine: work.logLine,
                        genre_v2: work.genre_v2,
                        format: work.format,
                        privacy: work.privacy || false,
                        status: work.status || '',
                        credit: work.credit || [],
                        credit: work.credit || [],
                        assetsAvailable: work.assetsAvailable || [], // Ensure assetsAvailable is saved
                        proof_link: work.proof_link || '',
                        language: work.language || '',
                        formatDetails: work.formatDetails || { runtime: null, seasons: null, episodes: null, episodeRuntime: null } // Ensure formatDetails is saved
                    })) : []
                },
                originalIps: Array.isArray(data.ownWorks.portfolio) ? data.ownWorks.portfolio.map(work => ({
                    _id: work._id,
                    poster: work.poster,
                    name: work.name,
                    logLine: work.logLine,
                    genre_v2: work.genre_v2,
                    format: work.format,
                    privacy: work.privacy || false,
                    status: work.status || '',
                    credited: work.credit || [],
                    assetsAvailable: work.assetsAvailable || [], // Ensure assetsAvailable is saved
                    proof_link: work.proof_link || '',
                    language: work.language || '',
                    assetsLanguage: work.assetsLanguage || '',
                    pitchReason: work.pitchReason || '',
                    formatDetails: work.formatDetails || { runtime: null, seasons: null, episodes: null, episodeRuntime: null } // Ensure formatDetails is saved
                })) : [],
                experience: Array.isArray(data.experience) ? data.experience.map(work => ({
                    name: work.name,
                    logLine: work.logLine,
                    genre: work.genre,
                    format: work.format,
                    privacy: work.privacy || false,
                    proof_link: work.proof_link || '',
                    date: work.date ? work.toDate.toISOString() : null,
                    toDate: work.date ? work.date.toISOString() : null,
                })) : [],
                storyOfmyLife: data.storyOfmyLife || { value: '', privacy: false },
                userPreference: data.userPreference || { publicOrder: ['ownWorks', 'experience', 'favourites'] }
            };

            // console.log("Submitting form with data:", profileData);
            // console.log(getValues())

            const response = await axios.post('/profile', { userId, ...profileData });

            // Check the response status
            if (response.status === 201) {
                showAlert('Profile saved successfully', 'success');
            } else {
                showAlert('Unexpected response from the server', 'warning');
            }

            // 1. Check if the portfolio/originalIps length is more than 0
            if (profileData.ownWorks.portfolio.length > 0) {
                // 2. If true, toggle isNewUser to false
                await axios.post(`/auth/toggleIsNewUser/${userId}`, { isNewUser: false });
            } else {
                await axios.post(`/auth/toggleIsNewUser/${userId}`, { isNewUser: true });
            }
            setOpenSnackbar(true);
        } catch (error) {
            // Check for specific error response status
            if (error.response && error.response.status === 400) {
                showAlert(error.response.data.message || 'Error saving profile', 'error');
            } else {
                showAlert('Network or server error. Please try again.', 'error');
            }
            console.error('Error saving profile:', error);
        } finally {
            setSaving(false);
        }
    }, [axios, userId, setOpenSnackbar, setSaving]);


    const handleSave = () => {
        methods.handleSubmit(onSubmitProfile, (errors) => {
            showAlert("Validation Erorr", "")
            console.log('Validation errors', errors);
        })();
    };


    const handleMediaUpdate = (updatedMedia) => {
        setMedia(updatedMedia);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Box width={"100vw"}>
            <FormProvider {...methods} xs={{ overflowY: "auto", width: "95vw" }}>
                <Container
                    sx={{
                        width: '100%',
                        position: 'relative',
                        marginTop: isMobile ? '100px' : '115px'
                    }}>
                    <Box
                        sx={{
                            display: 'flex !important',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            overflowX: 'auto',
                            width: isMobile ? '90vw' : '100%',
                            whiteSpace: 'nowrap',
                            flexGrow: 1,
                            position: 'fixed',  // Make the container sticky
                            top: isMobile ? '60px' : '10vh',  // Use hardcoded top value for mobile and desktop
                            zIndex: 10,  // Ensure it appears above content
                            backgroundColor: "black",
                            scrollbarWidth: 'none',  // For Firefox
                            '&::-webkit-scrollbar': {
                                display: 'none', // For Chrome, Safari, and Edge
                            },
                        }}
                    >

                        {config.sections.map((section, index) => (
                            <Button
                                key={section.key}
                                variant="outlined"
                                sx={{
                                    color: theme.palette.text.primary,
                                    padding: isMobile ? "3px 5px" : "5px 10px",
                                    margin: isMobile ? "5px" : "10px",
                                    minWidth: isMobile ? "120px" : "150px",
                                    fontSize: isMobile ? "12px" : "14px",
                                    fontWeight: "300",
                                    flexShrink: 0, // Prevents the buttons from shrinking too much
                                    ...(selectedTab === index && {
                                        backgroundColor: theme.palette.action.selected,
                                        borderColor: theme.palette.primary.main,
                                    }),
                                }}
                                onClick={(event) => handleChange(event, index)}
                            >
                                {section.label}
                            </Button>
                        ))}
                        <Button
                            variant="outlined"
                            sx={{
                                color: theme.palette.text.primary,
                                padding: isMobile ? "3px 5px" : "5px 10px",
                                margin: isMobile ? "5px" : "10px",
                                minWidth: isMobile ? "120px" : "170px",
                                fontSize: isMobile ? "12px" : "14px",
                                fontWeight: "300",
                                flexShrink: 0, // Prevents the buttons from shrinking too much
                            }}
                            onClick={handleCoWriterDialogOpen}
                        >
                            Manage Co-Written
                        </Button>

                        {/* Co-writer management dialog */}
                        <CoWriterManagementDialog
                            isOpen={isCoWriterDialogOpen}
                            onClose={handleCoWriterDialogClose}
                        />
                    </Box>
                    <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="xs">
                        <DialogTitle>Set Public Order Preference</DialogTitle>
                        <DialogContent dividers>
                            <PublicOrderPreference theme={theme} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} color="primary">
                                Close
                            </Button>
                            <Button onClick={handleCloseDialog} color="primary">
                                Okay
                            </Button>
                        </DialogActions>
                    </Dialog>



                    {config.sections.map((section, index) => {
                        const Component = sections[section.key];
                        const step = sectionMap[location.state?.redirectSection]?.step;

                        // Only pass `step` prop to specific components
                        const props = {
                            userId,
                            handleNextSection,
                            media,
                            onMediaUpdate: handleMediaUpdate,
                            isMobile: isMobile,
                            theme: theme,
                            handleSave
                        };
                        if (section.key === 'userDetails' || section.key === 'favourites') {
                            props.step = step;
                        }

                        return (
                            <TabPanel
                                key={section.key}
                                value={selectedTab}
                                index={index}
                                isMobile={isMobile}
                                sx={{
                                    paddingTop: isMobile ? '8px' : '24px', // Adjust padding for mobile
                                    margin: '0', // Reset any default margins for mobile
                                }}
                            >
                                {React.createElement(Component, props)}
                            </TabPanel>
                        );
                    })}
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: isMobile ? 150 : 120, // Ensure it stays above the Fab button
                            right: isMobile ? 16 : 25,
                            zIndex: 1000,
                            width: checklistOpen ? (isMobile ? '200px' : '250px') : '50px', // Adjust width for mobile
                            padding: isMobile ? '8px' : '16px', // Adjust padding for mobile
                            boxShadow: 3,
                            backgroundColor: 'black',
                            color: 'white',
                            borderRadius: 1,
                            transition: 'width 0.25s',
                            overflow: 'hidden',
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {checklistOpen && <Typography variant="h6" sx={{ fontSize: '1rem' }}>Profile Checklist</Typography>}
                            <IconButton onClick={toggleChecklist} sx={{
                                color: 'white',
                                outline: (checkPersonalDetails() && checkStories() && checkExperiences() && checkFavourites() && checkStoryOfMyLife()) ? 'none' : '2px dashed white',
                                animation: (checkPersonalDetails() && checkStories() && checkExperiences() && checkFavourites() && checkStoryOfMyLife()) ? 'none' : 'blinkingOutline 1s infinite',
                                '@keyframes blinkingOutline': {
                                    '0%': { outlineColor: 'white' },
                                    '50%': { outlineColor: 'transparent' },
                                    '100%': { outlineColor: 'white' },
                                },
                            }}>
                                {checklistOpen ? <CloseIcon /> : <InfoIcon />}
                            </IconButton>
                        </Box>
                        {checklistOpen && (
                            <List dense>
                                <ListItem button onClick={() => setSelectedTab(0)}>
                                    <ListItemIcon>
                                        {checkPersonalDetails() ? <CheckCircleIcon sx={{ color: 'white' }} /> : <RadioButtonUncheckedIcon sx={{ color: 'white' }} />}
                                    </ListItemIcon>
                                    <ListItemText primary={config.profileChecklist.personalDetails} primaryTypographyProps={{ fontSize: '0.875rem' }} />
                                </ListItem>
                                <ListItem button onClick={() => setSelectedTab(1)}>
                                    <ListItemIcon>
                                        {checkStories() ? <CheckCircleIcon sx={{ color: 'white' }} /> : <RadioButtonUncheckedIcon sx={{ color: 'white' }} />}
                                    </ListItemIcon>
                                    <ListItemText primary={config.profileChecklist.addStory} primaryTypographyProps={{ fontSize: '0.875rem' }} />
                                </ListItem>
                                <ListItem button onClick={() => setSelectedTab(2)}>
                                    <ListItemIcon>
                                        {checkExperiences() ? <CheckCircleIcon sx={{ color: 'white' }} /> : <RadioButtonUncheckedIcon sx={{ color: 'white' }} />}
                                    </ListItemIcon>
                                    <ListItemText primary={config.profileChecklist.addExperience} primaryTypographyProps={{ fontSize: '0.875rem' }} />
                                </ListItem>
                                <ListItem button onClick={() => setSelectedTab(3)}>
                                    <ListItemIcon>
                                        {checkFavourites() ? <CheckCircleIcon sx={{ color: 'white' }} /> : <RadioButtonUncheckedIcon sx={{ color: 'white' }} />}
                                    </ListItemIcon>
                                    <ListItemText primary={config.profileChecklist.addFavourites} primaryTypographyProps={{ fontSize: '0.875rem' }} />
                                </ListItem>
                                <ListItem button onClick={() => setSelectedTab(4)}>
                                    <ListItemIcon>
                                        {checkStoryOfMyLife() ? <CheckCircleIcon sx={{ color: 'white' }} /> : <RadioButtonUncheckedIcon sx={{ color: 'white' }} />}
                                    </ListItemIcon>
                                    <ListItemText primary={config.profileChecklist.storyOfMyLife} primaryTypographyProps={{ fontSize: '0.875rem' }} />
                                </ListItem>
                            </List>
                        )}
                    </Box>
                    <Fab
                        color="primary"
                        variant="extended"
                        sx={{
                            position: 'fixed',
                            bottom: isMobile ? '90px' : 16, // Closer to the bottom on mobile
                            right: isMobile ? 8 : 16, // Closer to the right on mobile
                            zIndex: 10,
                            fontSize: isMobile ? '12px' : '16px', // Adjust font size
                            padding: isMobile ? '6px 12px' : '8px 16px', // Adjust padding
                        }}
                        onClick={handleSave}
                    >
                        {saving ? 'Saving...' : methods.formState.isDirty ? (isMobile ? 'Save' : 'Save Profile') : 'Save'}
                    </Fab>


                </Container>
            </FormProvider>
            <Snackbar
                open={alert.open}
                autoHideDuration={3000}
                onClose={handleCloseAlert}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: isMobile ? 'center' : 'right', // Center for mobile, right for desktop
                }}
                sx={{
                    position: 'fixed',  // Fix the position on the viewport
                    bottom: isMobile ? '100px' : '20px',     // Keep the bottom 20px from the bottom on both mobile and desktop
                    // left: isMobile ? '20px' : 'auto',   // Position in the left corner for mobile
                    right: isMobile ? 'auto' : '200px',  // Position in the right corner for desktop
                    zIndex: 1400,       // Ensure it's above other content                
                }} // 100px from the bottom
            >
                <Alert onClose={handleCloseAlert} severity={alert.severity}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </Box >
    );
};

const TabPanel = (props) => {
    const { children, value, index, isMobile, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                        p: isMobile ? 3 : 0,  // Padding for all sides on mobile, 0 for desktop
                        pt: 3,               // Always keep padding-top: 3 (24px) for desktop
                    }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

export default ProfileFormComponent;
