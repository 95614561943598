import { z } from 'zod';

const optionalUrl = z.string().url().optional();

const experienceSchema = z.object({
  experience: z.array(z.object({
    poster: z.string().optional(),
    name: z.string().min(3, 'Must be at least 3 characters long'),
    logLine: z.string().min(10, 'Must be at least 10 characters long'),
    genre: z.string().optional(),
    format: z.string().min(3, 'Must be at least 3 characters long'),
    privacy: z.boolean().optional(),
    proof_link: optionalUrl,  // Cleaned up optionality
    date: z.preprocess((arg) => {
      if (arg instanceof Date) return arg;
      if (typeof arg === 'string') {
        const date = new Date(arg);
        if (!isNaN(date.getTime())) return date;
      }
      return undefined;
    }, z.date().optional()),
    toDate: z.preprocess((arg) => {
      if (arg instanceof Date) return arg;
      if (typeof arg === 'string') {
        const date = new Date(arg);
        if (!isNaN(date.getTime())) return date;
      }
      return undefined;
    }, z.date().optional()),
  })).optional(),
});

export default experienceSchema;
